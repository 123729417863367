import React from 'react';
import { normalTextColor } from '../../../constant/Color';
import logo from '../../../assets/images/red-packet/luniu-logo.png';
import { useTranslation } from 'react-i18next';
// import { createStyle } from '../../../utils/tools';

type FooterProps = {
  backgroundColor?: string;
  textColor?: string;
};

const Footer = ({
  backgroundColor = 'white',
  textColor = normalTextColor,
}: FooterProps) => {
  const { t } = useTranslation();

  return (
    <footer
      style={{ padding: '1rem 0', textAlign: 'center', backgroundColor }}
      className="footer"
    >
      <p
        style={{
          color: textColor,
          margin: 0,
          fontSize: '.8rem',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        {t('redPacket.footer')}
      </p>
      <img src={logo} alt="luniu-logo" width="35%" />
    </footer>
  );
};

// const styles = createStyle({});

export default Footer;

import React from 'react';

const NoMatch = () => {
  return (
    <div>
      <p>这里啥都没有</p>
    </div>
  );
};

export default NoMatch;

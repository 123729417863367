import axios from 'axios';

const BASE_URL = process.env.REACT_APP__REQUEST_BASE_URL
  ? process.env.REACT_APP__REQUEST_BASE_URL
  : 'https://api.luniumall.com/api/v1/';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

//Get Data without Auth token
export const getData = async (endPoint: string, options?: {}) => {
  try {
    const response = await axiosInstance.get(endPoint, {
      // timeout: 10000,
      ...options,
    });
    if (response.status === 200) {
      return validateAxiosResponse(response.data);
    } else console.error(response);
  } catch (err) {
    if (axios.isCancel(err)) {
      return err.message;
    } else {
      // alert('getData' + err + ' at endpoint: ' + endPoint);
      console.log('getData' + err + ' at endpoint: ' + endPoint);
    }
  }
};

export const postData = async (
  endPoint: string,
  body: object,
  options?: {}
) => {
  try {
    const response = await axiosInstance.post(endPoint, body, options);
    if (response.status === 200) {
      return validateAxiosResponse(response.data);
    } else console.error(response);
  } catch (err) {
    console.log('postData' + err + ' at endpoint: ' + endPoint);
  }
};

function validateAxiosResponse(response: { return_code?: number; data: any }) {
  if (response['return_code']) {
    switch (response['return_code']) {
      case 200:
        return {
          goodStatus: true,
          return_code: response.return_code,
          data: response.data,
        };
      default:
        return {
          goodStatus: false,
          return_code: response.return_code,
          data: response.data,
        };
    }
  } else {
    return {
      goodStatus: false,
      return_code: response.return_code,
      data: response.data,
    };
  }
}

import React, { useEffect } from 'react';
import arrow from '../assets/images/share/open-safari-instructions.png';
import wechatShare from '../assets/images/share/wechat-share.png';
import momentShare from '../assets/images/share/moment-share.png';
import { createStyle } from '../utils/tools';
import PageTitle from '../components/common/PageTitle';
import InputField from '../components/common/input/InputField';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import {
  setWechatWebConfig,
} from '../utils/wechatWebMethods';

const Share = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const queries = qs.parse(qs.extract(window.location.search));

    (async () => {
      if (queries.share_code) {
        if (typeof queries.share_code === 'string') {
          setWechatWebConfig('FREE Red Packet from Luniu Mall!',
          'Share it to your friends! Get an order discount!',
          'https://trello-attachments.s3.amazonaws.com/604ffa346c7cfc3aff52a596/6100534b30abe206a82bc894/a4f4a1cf119e0fdac3ee8d419c81f2fc/WeChat_Image_20210810124054.png',
          `https://events.luniumall.com/red-packet?share_code=${queries.share_code}`);
        }
      }
    })();
  }, []);

  return (
    <div>
      <PageTitle title="分享 Share" />
      <div style={styles.cover}>
        <img src={arrow} width="100%" alt="Arrow"/>
        <div style={styles.flexRowCenter}>
          <h1 style={styles.text}>{t('share.title')}</h1>
        </div>
        <div style={{ ...styles.flexRowCenter, marginTop: 15 }}>
          <div style={styles.iconContainer}>
            <div style={styles.icon}>
              <img src={wechatShare} width={70} alt="Wechat Share Icon"/>
            </div>
            <div style={{ ...styles.icon, marginTop: 15 }}>
              <img src={momentShare} width={70} alt="Wechat Moment Share Icon"/>
            </div>
          </div>
          <div style={styles.instructionsTextContainer}>
            <div style={styles.instructionsText}>
              <h1 style={styles.text}>{t('share.wechatShare')}</h1>
            </div>
            <div style={{ ...styles.instructionsText, marginTop: 15 }}>
              <h1 style={styles.text}>{t('share.momentShare')}</h1>
            </div>
          </div>
        </div>
        <div style={{ ...styles.flexRowCenter, marginTop: 70 }}>
          <InputField
            type="submit"
            placeholder={t('share.backButton')}
            style={{ width: 270, height: 45 }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = createStyle({
  cover: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 999,
    display: 'block',
  },
  tips: {
    width: '30vw',
    minWidth: 375,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  text: {
    color: 'white',
    margin: 0,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    backgroundColor: 'white',
    borderRadius: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructionsTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
  },
  instructionsText: {
    height: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default Share;

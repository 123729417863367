import React from 'react';
import { createStyle, isIOS } from '../utils/tools';

//Images
import BackgroundImg from '../assets/images/download/luniu-download.gif';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/common/PageTitle';

const DownloadPromoteScreen = () => {
  const { t } = useTranslation();

  return (
    <div style={styles.root}>
      <PageTitle
        title={t('download.title')}
        extraMeta={
          <meta name="download-app" content="Download Luniu Mall APP" />
        }
      />
      {/** Background images to download app */}
      <img
        src={BackgroundImg}
        style={{ width: '100%' }}
        alt="download-bg"
        onClick={() => {
          if (isIOS()) {
            // window.open(
            //   'https://apps.apple.com/ca/app/id1525586546',
            //   '_blank',
            //   'noreferrer'
            // );
            window.location.href = 'https://apps.apple.com/ca/app/id1525586546';
          } else {
            // window.open(
            //   'https://tools.luniumall.com/download',
            //   '_blank',
            //   'noreferrer'
            // );
            window.location.href = 'https://tools.luniumall.com/download';
          }
        }}
      />
    </div>
  );
};

const styles = createStyle({
  cover: {
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 999,
    width: '100%',
    height: '100%',
  },
  tipImg: {
    maxWidth: '100%',
  },
  downloadContainer: {
    backgroundColor: '#def5c0',
  },
});

export default DownloadPromoteScreen;

import React from 'react';
import Footer from '../components/common/layout/Footer';
import { createStyle, isIOS, isWechat } from '../utils/tools';
import PageTitle from '../components/common/PageTitle';

//Images
import BackgroundImg from '../assets/images/download/download-bg.jpg';
import tip from '../assets/images/download/upright-tips.png';
import AppStoreIcon from '../assets/images/download/app-store.png';
import GooglePlayIcon from '../assets/images/download/google-play-badge.png';
import AndroidIcon from '../assets/images/download/android-download.png';
import { useTranslation } from 'react-i18next';

const DownloadScreen = () => {
  const { t } = useTranslation();

  return (
    <div style={styles.root}>
      <PageTitle
        title={t('download.title')}
        extraMeta={
          isIOS() ? (
            <meta
              http-equiv="refresh"
              content="0; URL=https://apps.apple.com/ca/app/id1525586546"
            />
          ) : (
            <meta
              name="download-app"
              content="download via google play or apk file"
            />
          )
        }
      />
      {/* Show cover to block screen if it is in wechat */}
      {isWechat() && (
        <div className="cover" style={styles.cover}>
          <div style={styles.tipContainer}>
            <img style={styles.tipImg} src={tip} alt="open browser tip" />
          </div>
        </div>
      )}
      <main style={styles.main}>
        <div className="download" style={styles.downloadContainer}>
          <p style={styles.downloadTitle}>{t('download.clickToDownload')}</p>

          {isIOS() ? (
            <div id="ios" style={styles.iosContainer}>
              <a
                className="download-btn apple"
                href="https://apps.apple.com/ca/app/id1525586546"
              >
                <img src={AppStoreIcon} alt="Apple Store icon" />
              </a>
            </div>
          ) : (
            <div id="android" style={styles.androidContainer}>
              <a href="https://play.google.com/store/apps/details?id=com.luniumall.luniumall">
                <img
                  style={{ width: 350, maxWidth: '100%' }}
                  src={GooglePlayIcon}
                  alt="Google play icon"
                />
              </a>
              <h4 style={{ color: 'white' }}>{t('download.or')}</h4>
              <a href="https://luniumall.com/files/LuniuMall.apk" download>
                <img
                  style={{ width: 350, maxWidth: '100%' }}
                  src={AndroidIcon}
                  alt="Android icon"
                />
              </a>
            </div>
          )}
        </div>
      </main>
      <Footer backgroundColor="#fdba52" textColor="white" />
    </div>
  );
};

const styles = createStyle({
  root: {
    backgroundColor: '#fdba52',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  cover: {
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 999,
    width: '100%',
    height: '100%',
  },
  tipContainer: {},
  tipImg: {
    maxWidth: '100%',
  },
  main: {
    maxWidth: 1024,
    margin: '0 auto',
    background: `#fdba52 url(${BackgroundImg}) no-repeat top center`,
    backgroundSize: 'cover',
    padding: 10,
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  downloadContainer: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    padding: '2rem',
    maxWidth: 375,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 20,
    margin: '0 auto',
  },
  downloadTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 0,
  },
  iosContainer: {
    alignSelf: 'center',
  },
  androidContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default DownloadScreen;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  backGroundLight,
  subTextColor,
  tintColorLight,
} from '../../constant/Color';
import request from '../../utils/request';
import { createStyle } from '../../utils/tools';

type GetCodeProps = {
  enableBtn: boolean | undefined;
  phoneNumber: string | undefined;
};

const GetCode = ({ enableBtn = false, phoneNumber }: GetCodeProps) => {
  const { t } = useTranslation();

  const [countdown, setCountdown] = useState(60);
  const [shouldCoolDown, setShouldCoolDown] = useState(false);

  useEffect(() => {
    let interval: any = null;
    if (shouldCoolDown && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((seconds) => seconds - 1);
      }, 1000);
    } else if (shouldCoolDown && countdown <= 0) {
      clearInterval(interval);
      setShouldCoolDown(false);
      setCountdown(60);
    }
    return () => {
      clearInterval(interval);
    };
  }, [shouldCoolDown, countdown]);

  const handleSendCode = async () => {
    if (enableBtn) {
      if (phoneNumber) {
        setShouldCoolDown(true);
        const result = await sendSMSCode(phoneNumber);
        if (result.goodStatus) {
          toast(`${result.data}`, { type: 'success' });
        } else {
          toast(`${result.return_code}:${result.data}`, { type: 'error' });
          setShouldCoolDown(false);
        }
      }
    } else {
      toast(t('register.validation.enterPhoneNumber'), { type: 'error' });
    }
  };

  return (
    <div style={styles.codeContainer}>
      {shouldCoolDown ? (
        <p style={styles.coolDownText}>
          {t('register.validation.codeCoolDown', { s: countdown })}
        </p>
      ) : (
        <div
          style={enableBtn ? styles.sendBtn : styles.disableSendBtn}
          onClick={handleSendCode}
        >
          {t('register.validation.getCode')}
        </div>
      )}
    </div>
  );
};

const styles = createStyle({
  codeContainer: {
    fontSize: 12,
    paddingLeft: 10,
    borderLeftColor: backGroundLight,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
  },
  sendBtn: {
    borderRadius: 20,
    borderColor: tintColorLight,
    color: tintColorLight,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
    cursor: 'pointer',
  },
  disableSendBtn: {
    borderRadius: 20,
    borderColor: backGroundLight,
    color: subTextColor,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
    backgroundColor: backGroundLight,
  },
  coolDownText: {
    margin: 0,
    color: subTextColor,
  },
});

const sendSMSCode = async (phone: string) => {
  const result = await request.post('send_code', {
    data: {
      receiver: phone,
      type: 'phone',
    },
  });
  return result;
};

export default GetCode;

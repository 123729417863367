import React from 'react';
import { createStyle } from '../../utils/tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
/** @ts-ignore */
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

type QRCodeModalProps = {
  show: boolean;
  onClose: Function;
  imgURL?: string;
  lang?: string;
};

const QRCodeModal = ({ show, onClose, imgURL, lang }: QRCodeModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      {show ? (
        <div style={styles.body}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '90%',
              marginBottom: 10,
            }}
          >
            <button
              style={{
                background: 'transparent',
                boxShadow: '0px 0px 0px transparent',
                border: '0px solid transparent',
                textShadow: '0px 0px 0px transparent',
              }}
              onClick={() => onClose()}
            >
              <FontAwesomeIcon icon={faTimesCircle} color="white" size="2x" />
            </button>
          </div>
          <div style={styles.modal}>
            <div style={styles.content}>
              <QRCode
                size={200}
                value="https://app.luniumall.com/home"
                level="H"
                imageSettings={{
                  src: imgURL || 'https://luniumall.com/images/app/appicon.png',
                  height: 82,
                  width: 82,
                }}
              />
              <div>
                <p style={{ fontSize: 20, marginTop: 10, marginBottom: 0 }}>
                  {t('redPacket.qrCode.description')}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = createStyle({
  body: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 99,
  },
  content: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    width: '90%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    transition: '1.1s ease-out',
    boxShadow: '-2rem 2rem 2rem rgba(0,0,0,0.2)',
    opacity: 1,
    visibility: 'visible',
    borderRadius: 20,
  },
});

export default QRCodeModal;

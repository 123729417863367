import React from 'react';
import { Helmet } from 'react-helmet';

type PageTitleProps = {
  title?: string;
  extraMeta?: JSX.Element;
};

const PageTitle = ({ title, extraMeta }: PageTitleProps) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
        />
        <meta name="title" content="陆牛商城 Luniu Mall"></meta>
        <meta
          name="description"
          content="Luniu Mall is a fresh food distribution platform. Relying on the online e-commerce platform, it provides users with global fresh fruits and vegetables and high-quality foods. Focus on purchasing from the place of origin, direct delivery, and professional cold chain storage and shipping."
        ></meta>
        {extraMeta && extraMeta}
        <title>
          {title !== undefined
            ? title + ' | 陆牛商城 Luniu Mall'
            : '陆牛商城 Luniu Mall'}
        </title>
      </Helmet>
    </>
  );
};

export default PageTitle;

import axios from 'axios';
/** @ts-ignore */
import WechatJSSDK from 'wechat-jssdk';

export let defaultWeChatShareConfig = {
  title: '',
  desc: '',
  logo: '',
};

var wx: any;

/**
 * For Web, set the basic config for wechat available menus
 */
export function setWechatWebConfig(
  title: string,
  desc = defaultWeChatShareConfig.desc,
  logo = defaultWeChatShareConfig.logo,
  link: string
) {
  axios
    .post('https://testapi.luniumall.com/api/v1/wechat/jssdk', {
      url: window.location.href,
    })
    .then((result) => {
      if (result && result.status === 200) {
        const configs = result.data.data;
        const wechatObj = new WechatJSSDK({
          debug: configs.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: configs.appId, // 必填，公众号的唯一标识
          timestamp: configs.timestamp, // 必填，生成签名的时间戳
          nonceStr: configs.nonceStr, // 必填，生成签名的随机串
          signature: configs.signature, // 必填，签名
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'showMenuItems',
            'hideMenuItems',
          ], // 必填，需要使用的JS接口列表
        });
        // wx = await wechatObj.getOriginalWx();
        wechatObj
          .initialize()
          .then((w: any) => {
            w.shareOnChat({
              type: 'link',
              title: title,
              desc: desc,
              imgUrl: logo,
              link: link,
            });
          })
          .catch((err: any) => {
            console.error(err);
          });
        // wx.config({
        //   debug: configs.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //   appId: configs.appId, // 必填，公众号的唯一标识
        //   timestamp: configs.timestamp, // 必填，生成签名的时间戳
        //   nonceStr: configs.nonceStr, // 必填，生成签名的随机串
        //   signature: configs.signature, // 必填，签名
        //   jsApiList: [
        //     'updateAppMessageShareData',
        //     'updateTimelineShareData',
        //     'showMenuItems',
        //     'hideMenuItems',
        //   ], // 必填，需要使用的JS接口列表
        // });
        // setWechatMenu();

        // setWechatSharePage(
        //   `${title}`,
        //   desc,
        //   logo,
        //   link
        // );

        // wx.error((res: any) => {
        //   console.log('catch config error :: =============== ::', res);
        // });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// function setWechatMenu() {
//   if (wx !== undefined) {
//     wx.ready(() => {
//       wx.showMenuItems({
//         menuList: [
//           'menuItem:share:appMessage',
//           'menuItem:share:timeline',
//           'menuItem:favorite',
//           'menuItem:share:facebook',
//           'menuItem:openWithSafari',
//           'menuItem:openWithQQBrowser',
//         ],
//       });
//       wx.hideMenuItems({
//         menuList: [
//           // 'menuItem:copyUrl',
//           'menuItem:share:email',
//           'menuItem:share:qq',
//         ],
//       });
//     });
//   } else {
//     console.log('Cannot find Wechat JS Api');
//   }
// }

/**
 * Wechat share config
 * @param title
 * @param desc
 * @param imgUrl
 */
export function setWechatSharePage(
  title: string,
  desc: string,
  imgUrl: string,
  link?: string
) {
  if (wx !== undefined) {
    wx.ready(function () {
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
          // console.log('setting share config success');
        },
      });

      wx.updateTimelineShareData({
        title: title, // 分享标题
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
          // console.log('setting share config success');
        },
      });
    });
  } else {
    console.log('Cannot find Wechat JS Api');
  }
}

export const resetWechatSharePage = (
  title: string,
  desc = defaultWeChatShareConfig.desc,
  logo = defaultWeChatShareConfig.logo,
  link: string,
  timeOut?: number
) => {
  setTimeout(() => {
    setWechatSharePage(
      `${title} - ${defaultWeChatShareConfig.title}`,
      desc,
      logo,
      link
    );
  }, timeOut ?? 10);
};

import React from 'react';
import { createStyle } from '../../utils/tools';
import LoadingImg from '../../assets/images/red-packet/loading.gif';

type LoadingModalProps = {
  show: boolean;
};

const LoadingModal = ({ show }: LoadingModalProps) => {
  return show ? (
    <div style={styles.body}>
      <img src={LoadingImg} alt="loading" width="15%" />
    </div>
  ) : (
    <></>
  );
};

const styles = createStyle({
  body: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 99,
  },
});

export default LoadingModal;

/**
 *
 * @param styleObject CSS in JS style object
 */
export const createStyle = (styleObject: {
  [key: string]: React.CSSProperties;
}) => styleObject;

/**
 * Check if the browser is from iOS
 */
export function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

/**
 * Check if is Wechat browser
 */
export function isWechat() {
  const userAgent = navigator.userAgent;
  return !!/MicroMessenger/i.test(userAgent);
}

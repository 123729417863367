import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

//React-Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Pages
// import Home from './pages/Home';
import Register from './pages/Register';
import i18n from './i18n';
import DownloadScreen from './pages/DownloadScreen';
import NoMatch from './pages/NoMatch';
import CardVerificationScreen from './pages/CardVerificationScreen';
import PromotionReceive from './pages/PromotionReceive';
import Share from './pages/Share';
import DownloadPromoteScreen from './pages/DownloadPromoteScreen';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Switch>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/download">
            <DownloadScreen />
          </Route>
          <Route path="/xhs">
            <DownloadPromoteScreen />
          </Route>
          <Route path="/card-verification">
            <CardVerificationScreen />
          </Route>
          <Route path="/red-packet">
            <PromotionReceive />
          </Route>
          <Route path="/share">
            <Share />
          </Route>
          <Route exact path="/">
            {/* <Home /> */}
            <Redirect to="/download" />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
        limit={1}
      />
    </I18nextProvider>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/common/PageTitle';
import { createStyle } from '../utils/tools';
import { useForm } from 'react-hook-form';
import qs from 'query-string';

import BackgroundImg from '../assets/images/register/register-bg.jpg';
import InputField from '../components/common/input/InputField';
import { toast } from 'react-toastify';
import GetCode from '../components/Register/GetCode';
import Footer from '../components/common/layout/Footer';
import request from '../utils/request';
import { backGroundLight } from '../constant/Color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const phoneNumberRegex = RegExp(
  /(^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$)|^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[235-8]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|66\d{2})\d{6}$/
);

const Register = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch, reset } = useForm({
    shouldFocusError: false,
  });
  const watchPhoneNumber: string | undefined = watch('phone');

  const [inviterInfo, setInviterInfo] = useState({
    name: '',
    imgUrl: '',
  });
  const [isValidCode, setIsValidCode] = useState(false);
  const [inviteCode, setInviteCode] = useState('');

  useEffect(() => {
    //Get Invite Code from URL
    const queries = qs.parse(qs.extract(window.location.search));

    if (queries.code) {
      (async () => {
        if (typeof queries.code === 'string') {
          setInviteCode(queries.code);
          const result = await getInviterInfo(queries.code);
          if (result.goodStatus) {
            setInviterInfo({
              name: result.data.nick_name,
              imgUrl: result.data.user_picture,
            });
            setIsValidCode(true);
          } else {
            setIsValidCode(false);
            toast(typeof result.data === 'string' ? result.data : 'Error', {
              type: 'error',
              autoClose: false,
            });
          }
        } else {
          setIsValidCode(false);
          toast(t('register.errors.missCode'), {
            type: 'error',
            autoClose: false,
          });
        }
      })();
    } else {
      setIsValidCode(false);
      toast(t('register.errors.missCode'), { type: 'error', autoClose: false });
    }
  }, [t]);

  const onSubmit = async (data: any) => {
    const result = await registerUser(
      data.phone,
      data.password,
      data.passwordConfirm,
      data.code,
      inviteCode
    );
    if (result && result.goodStatus) {
      toast(t('register.successfullyRegister'), {
        type: 'success',
        autoClose: false,
        onClose: () =>
          window.location.replace(
            window.location.href.replace('register', 'download')
          ),
      });
      reset();
    } else {
      toast(`${result.return_code}:${result.data}`, { type: 'error' });
    }
  };
  const onError = (errors: any) => {
    toast.clearWaitingQueue();
    if (errors.phone) {
      toast(errors.phone.message, { type: 'error' });
    } else if (errors.password) {
      toast(errors.password.message, { type: 'error' });
    } else if (errors.passwordConfirm) {
      toast(errors.passwordConfirm.message, { type: 'error' });
    } else if (errors.code) {
      toast(errors.code.message, { type: 'error' });
    }
  };

  return (
    <div className="bg" style={styles.bg}>
      <div style={styles.root}>
        <main style={styles.container}>
          <PageTitle title={t('register.title')} />
          <h1 style={styles.title}>{t('register.title')}</h1>

          <section
            className="form-container"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
            }}
          >
            {inviterInfo.name !== '' && (
              <div style={styles.inviterContainer}>
                <img
                  style={styles.inviterImg}
                  src={inviterInfo.imgUrl}
                  alt="inviter snapshot"
                />
                <p style={{ textAlign: 'center', color: 'white' }}>
                  {t('register.friendInfo', { name: inviterInfo.name })}
                </p>
              </div>
            )}
            {isValidCode && (
              <>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  style={styles.form}
                >
                  <InputField
                    name="phone"
                    type="tel"
                    refValue={register({
                      required: `${t('register.validation.enterPhoneNumber')}`,
                      pattern: {
                        message: `${t('register.validation.invalidPhone')}`,
                        value: phoneNumberRegex,
                      },
                    })}
                    placeholder={t('register.validation.enterPhoneNumber')}
                    leftComponent={<PhoneRegionSelector />}
                  />
                  <InputField
                    name="password"
                    type="password"
                    refValue={register({
                      required: `${t('register.validation.enterPassword')}`,
                      minLength: {
                        value: 6,
                        message: `${t('register.validation.tooShort')}`,
                      },
                    })}
                    placeholder={t('register.validation.enterPassword')}
                  />
                  <InputField
                    name="passwordConfirm"
                    type="password"
                    refValue={register({
                      required: `${t('register.validation.enterPassword')}`,
                      minLength: {
                        value: 6,
                        message: `${t('register.validation.tooShort')}`,
                      },
                      validate: (value) =>
                        value === watch('password') ||
                        `${t('register.validation.passwordDoNotMatch')}`,
                    })}
                    placeholder={t('register.validation.confirmPassword')}
                  />
                  <InputField
                    name="code"
                    type="text"
                    refValue={register({
                      required: `${t('register.validation.enterCode')}`,
                    })}
                    placeholder={t('register.validation.codeHint')}
                    rightComponent={
                      <GetCode
                        enableBtn={
                          typeof watchPhoneNumber === 'string' &&
                          phoneNumberRegex.test(watchPhoneNumber)
                        }
                        phoneNumber={watchPhoneNumber}
                      />
                    }
                    maxLength={6}
                  />
                  <InputField
                    type="submit"
                    placeholder={t('register.registerBtn')}
                  />
                </form>
                <p
                  style={{
                    textAlign: 'center',
                    color: 'white',
                    maxWidth: 300,
                    margin: '1rem auto 0',
                    fontSize: 12,
                  }}
                >
                  {t('register.agreePrivacyPolicy')}
                  <a
                    target="blank"
                    href="https://luniumall.com/privacy-policy/"
                  >
                    {t('register.luniuPrivacyPolicy')}
                  </a>
                </p>
              </>
            )}
          </section>
          <section className="eventDetail" style={styles.eventDetailContainer}>
            <h3 style={styles.eventDetailTitle}>
              {t('register.eventDetail.title')}
            </h3>
            <ul style={styles.eventDetailList}>
              <li>{t('register.eventDetail.descriptions.1')}</li>
              <li>{t('register.eventDetail.descriptions.2')}</li>
              <li>{t('register.eventDetail.descriptions.3')}</li>
              <li>{t('register.eventDetail.descriptions.4')}</li>
              <li>{t('register.eventDetail.descriptions.5')}</li>
            </ul>
          </section>
        </main>
        <Footer backgroundColor="#029D43" textColor="white" />
      </div>
    </div>
  );
};

const MAX_WIDTH = 414;

const ratio =
  window.innerWidth > MAX_WIDTH
    ? '38vh'
    : Math.floor((window.innerWidth / window.innerHeight) * 100) + 15 + 'vh';

const styles = createStyle({
  bg: {
    // backgroundColor: '#029D43',
  },

  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#029D43',
    minHeight: '100vh',
    justifyContent: 'space-between',
    backgroundImage: `url(${BackgroundImg})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maxWidth: MAX_WIDTH,
    margin: '0 auto',
  },
  container: {
    // backgroundColor: '#FDAE7E',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: ratio,
  },
  title: {
    textAlign: 'center',
    opacity: 0,
    position: 'absolute',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: MAX_WIDTH,
    // margin: '0 auto',
    width: '100vw',
  },
  eventDetailContainer: {
    maxWidth: MAX_WIDTH,
    margin: '1rem',
    padding: '.2rem 0',
    color: 'white',
  },
  eventDetailTitle: {
    textAlign: 'center',
    margin: 0,
  },
  eventDetailList: {
    margin: 0,
    padding: 10,
    fontSize: '.8rem',
    listStyle: 'none',
  },
  regionSelectorContainer: {
    fontSize: 12,
    borderRightColor: backGroundLight,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    paddingRight: 12,
    position: 'relative',
    marginRight: 6,
  },
  regionSelector: {
    all: 'unset',
    WebkitAppearance: 'none',
    outline: 'none',
    border: 'none',
    backgroundColor: 'none',
  },
  arrowIcon: {
    position: 'absolute',
    right: 3,
    top: '25%',
  },
  inviterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inviterImg: {
    borderRadius: '50%',
    width: 60,
    height: 60,
  },
});

const registerUser = async (
  username: string,
  pwd: string,
  confirmPwd: string,
  code: string,
  inviteCode: string
) => {
  const result = await request.post('user/register', {
    data: {
      username: username,
      password: pwd,
      confirm_password: confirmPwd,
      code: code,
      invite_code: inviteCode,
    },
  });
  return result;
};

const PhoneRegionSelector = () => {
  const { t } = useTranslation();
  return (
    <div style={styles.regionSelectorContainer}>
      <select name="region" style={styles.regionSelector}>
        <option value="NA">{t('register.validation.region.NA')}</option>
        <option value="CN">{t('register.validation.region.CN')}</option>
      </select>
      <FontAwesomeIcon icon={faAngleDown} style={styles.arrowIcon} />
    </div>
  );
};

const getInviterInfo = async (code: string) => {
  const result = await request.get('user/referrer', {
    params: {
      invite_code: code,
    },
  });

  return result;
};

export default Register;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../components/common/input/InputField';
import Footer from '../components/common/layout/Footer';
import PageTitle from '../components/common/PageTitle';
import { tintColorLight } from '../constant/Color';
import { createStyle } from '../utils/tools';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import AppIcon from '../assets/images/icon/appicon.png';
import CardSample from '../assets/images/card-verification/card-sample.png';
import BackgroundImg from '../assets/images/card-verification/bg.png';
import CheckMark from '../assets/images/card-verification/check-mark.svg';

import qs from 'query-string';
import { postData } from '../utils/axiosRequest';
import i18n from '../i18n';

const CardVerificationScreen = () => {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm({
    shouldFocusError: false,
  });

  const [completeRecaptcha, setCompleteRecaptcha] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSent, setFormSent] = useState(false);

  const onChangeRechatcha = () => {
    setCompleteRecaptcha(true);
  };

  const onSubmit = async (data: any) => {
    toast.clearWaitingQueue();
    const { code } = qs.parse(qs.extract(window.location.search));
    const submitData = {
      code: typeof code === 'string' ? code : 'ErrorCode',
      name: data.name,
      card: `${data.cardFront}********${data.cardRear}`,
      cc_img: data.cardPhoto['0'],
      id_img: data.photoId['0'],
    };
    setIsSubmitting(true);
    const result = await uploadIdentification(submitData);

    if (result && result.goodStatus) {
      setFormSent(true);
    } else if (result && typeof result.data === 'string') {
      toast(result.data, { type: 'error' });
      setIsSubmitting(false);
    } else {
      toast('Request Fail', { type: 'error' });
      setIsSubmitting(false);
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
    toast.clearWaitingQueue();
    let msg = '';
    Object.values(errors).forEach((item: any) => {
      msg += `${item.message} \n `;
    });
    toast(msg, { type: 'error' });
  };

  const handleLanguageChange = () => {
    i18n.changeLanguage(i18n.language !== 'en' ? 'en' : 'zh');
  };

  return (
    <div style={styles.root}>
      <PageTitle title={t('cardVerification.title')} />
      <main style={styles.main}>
        <p style={styles.languageSwitcher} onClick={handleLanguageChange}>
          {i18n.language !== 'en' ? 'English' : '中文'}
        </p>
        <h2 style={styles.title}>
          <img style={styles.logo} src={AppIcon} alt="Luniu Mall Icon" />
          {t('cardVerification.brandName')}
        </h2>

        <h2 style={styles.title}>{t('cardVerification.title')}</h2>

        {formSent ? (
          <div style={styles.successInfoContainer}>
            <img src={CheckMark} style={styles.checkMark} alt="Check mark" />
            <p style={styles.successInfo}>{t('cardVerification.successMsg')}</p>
          </div>
        ) : (
          <>
            <p style={styles.description}>
              {t('cardVerification.description1')}
            </p>
            <p style={styles.description}>
              {t('cardVerification.description2')}
            </p>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div style={styles.inputFieldContainer}>
                <label style={styles.inputLabel} htmlFor="name">
                  {t('cardVerification.fields.name.label')}
                </label>
                <InputField
                  type="text"
                  name="name"
                  placeholder={t('cardVerification.fields.name.placeholder')}
                  refValue={register({
                    required: {
                      message: t('cardVerification.validation.requiredField', {
                        field: t('cardVerification.fields.name.label'),
                      }),
                      value: true,
                    },
                    minLength: 2,
                  })}
                />
              </div>
              <div style={styles.inputFieldContainer}>
                <label style={styles.inputLabel}>
                  {t('cardVerification.fields.cardFront4.label')}
                </label>
                <InputField
                  type="text"
                  name="cardFront"
                  placeholder={t(
                    'cardVerification.fields.cardFront4.placeholder'
                  )}
                  refValue={register({
                    required: {
                      message: t('cardVerification.validation.requiredField', {
                        field: t('cardVerification.fields.cardFront4.label'),
                      }),
                      value: true,
                    },
                    pattern: {
                      message: t(
                        'cardVerification.validation.cardNumberLength'
                      ),
                      value: /[0-9]{4}/,
                    },
                  })}
                />
              </div>
              <div style={styles.inputFieldContainer}>
                <label style={styles.inputLabel}>
                  {t('cardVerification.fields.cardRear4.label')}
                </label>
                <InputField
                  type="text"
                  name="cardRear"
                  placeholder={t(
                    'cardVerification.fields.cardRear4.placeholder'
                  )}
                  refValue={register({
                    required: {
                      message: t('cardVerification.validation.requiredField', {
                        field: t('cardVerification.fields.cardRear4.label'),
                      }),
                      value: true,
                    },
                    pattern: {
                      message: t(
                        'cardVerification.validation.cardNumberLength'
                      ),
                      value: /[0-9]{4}/,
                    },
                  })}
                />
              </div>
              <div style={styles.uploadFieldContainer}>
                <label style={styles.inputLabel}>
                  {t('cardVerification.fields.cardPhoto.label')}
                </label>
                <span style={styles.smallTip}>
                  {t('cardVerification.fields.cardPhoto.tip')}
                </span>
                <img
                  src={CardSample}
                  style={styles.cardSample}
                  alt="credit card sample"
                />
                <input
                  type="file"
                  name="cardPhoto"
                  accept="image/*"
                  ref={register({
                    required: {
                      message: t('cardVerification.validation.requiredField', {
                        field: t('cardVerification.fields.cardPhoto.label'),
                      }),
                      value: true,
                    },
                  })}
                />
              </div>
              <div style={styles.uploadFieldContainer}>
                <label style={styles.inputLabel}>
                  {t('cardVerification.fields.idPhoto.label')}
                </label>
                <span style={styles.smallTip}>
                  {t('cardVerification.fields.idPhoto.tip')}
                </span>
                <input
                  type="file"
                  name="photoId"
                  accept="image/*"
                  ref={register({
                    required: {
                      message: t('cardVerification.validation.requiredField', {
                        field: t('cardVerification.fields.idPhoto.label'),
                      }),
                      value: true,
                    },
                  })}
                />
              </div>
              <div style={styles.centerFieldContainer}>
                <ReCAPTCHA
                  sitekey="6LfSYiMaAAAAABsEUz2tX-b45Nph4Sb5IKlRFnIw"
                  onChange={onChangeRechatcha}
                  onExpired={() => setCompleteRecaptcha(false)}
                />
              </div>
              <div style={styles.submitBtnContainer}>
                <InputField
                  type="submit"
                  accent
                  placeholder={
                    isSubmitting
                      ? t('cardVerification.uploading')
                      : t('cardVerification.submit')
                  }
                  buttonDisabled={
                    !agreeTerms || !completeRecaptcha || isSubmitting
                  }
                />
              </div>
              <div style={styles.centerFieldContainer}>
                <input
                  type="checkbox"
                  name="agree-box"
                  id="agree-box"
                  checked={agreeTerms}
                  onChange={() => setAgreeTerms((prev) => !prev)}
                />
                <label style={styles.inputLabelSmall} htmlFor="agree-box">
                  {t('cardVerification.agreeTerm')}
                </label>
              </div>
            </form>
          </>
        )}
      </main>
      <Footer backgroundColor={'transparent'} />
    </div>
  );
};

const MAX_WIDTH = 414;

const styles = createStyle({
  root: {
    backgroundColor: tintColorLight,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    backgroundImage: `url(${BackgroundImg})`,

    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  main: {
    maxWidth: MAX_WIDTH,
    margin: '0 auto',
    backgroundColor: tintColorLight,
    padding: 10,
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
  },
  logo: {
    width: 60,
    height: 60,
    display: 'block',
    margin: 10,
  },
  title: {
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    color: 'white',
    fontSize: '.9rem',
  },
  inputLabel: {
    color: 'white',
  },
  inputLabelSmall: {
    color: 'white',
    fontSize: '.8rem',
    textAlign: 'center',
  },
  inputFieldContainer: {
    // alignItems: 'center',
  },
  uploadFieldContainer: {
    marginBottom: '.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  centerFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
  },
  cardDigitInput: {
    display: 'flex',
    alignItems: 'center',
  },
  submitBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  smallTip: {
    fontSize: 12,
    marginLeft: 3,
    color: 'white',
    marginBottom: '.2rem',
  },
  cardSample: {
    maxWidth: '100%',
    width: 280,
    margin: '.3rem auto',
  },
  successInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '30%',
  },
  successInfo: {
    fontSize: 20,
    color: 'white',
  },
  checkMark: {
    width: 80,
    height: 80,
  },
  languageSwitcher: {
    margin: 0,
    color: 'white',
    alignSelf: 'flex-end',
    borderColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    padding: 5,
    fontSize: 12,
  },
});

const uploadIdentification = async (data: {
  code: string;
  name: string;
  card: string;
  cc_img: File;
  id_img: File;
}) => {
  const formData = new FormData();

  formData.append('code', data.code);
  formData.append('name', data.name);
  formData.append('card', data.card);
  formData.append('cc_img', data.cc_img);
  formData.append('id_img', data.id_img);

  const result = await postData(`identity/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return result;
};

export default CardVerificationScreen;

/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { toast } from 'react-toastify';
import { extend } from 'umi-request';

const BASE_URL = process.env.REACT_APP__REQUEST_BASE_URL
  ? process.env.REACT_APP__REQUEST_BASE_URL
  : 'https://api.luniumall.com/api/v1/';

// const codeMessage = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };

/**
 * 异常处理程序
 */
const errorHandler = (error: any) => {
  let { response = {} } = error;

  if (response) {
    //   codeMessage[response.status] ||
    const errortext = response.statusText;
    const { status, url } = response;

    toast(`请求错误 ${status}: ${url},${errortext}`, { type: 'error' });
  } else {
    response = {};
    response.goodStatus = false;
    response.data = error.type;
    return response;
  }
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
  prefix: BASE_URL,
});

request.interceptors.response.use(async (response) => {
  let responseData = await response.clone().json();
  responseData = validateResponse(responseData);
  return responseData;
});

export default request;

function validateResponse(response: { return_code?: number; data: any }) {
  if (response['return_code']) {
    switch (response['return_code']) {
      case 200:
        return {
          goodStatus: true,
          return_code: response.return_code,
          data: response.data,
        };
      default:
        return {
          goodStatus: false,
          return_code: response.return_code,
          data: response.data,
        };
    }
  } else {
    return {
      goodStatus: false,
      return_code: response.return_code,
      data: response.data,
    };
  }
}

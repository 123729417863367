import React from 'react';
import { accentColor, tintColorLight } from '../../../constant/Color';
import { createStyle } from '../../../utils/tools';

type InputFieldProps = {
  refValue?:
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  type: 'number' | 'text' | 'password' | 'submit' | 'tel' | 'checkbox';
  placeholder?: string;
  name?: string;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
  maxLength?: number;
  accent?: boolean;
  buttonDisabled?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
};

const InputField = ({
  refValue,
  type,
  name,
  placeholder,
  leftComponent,
  rightComponent,
  maxLength,
  accent = false,
  buttonDisabled = false,
  style,
  onClick,
}: InputFieldProps) => {
  switch (type) {
    case 'password':
    case 'text':
    case 'tel':
    case 'number':
      return (
        <div style={styles.inputContainer}>
          {leftComponent !== undefined && leftComponent}
          <input
            style={styles.inputStyle}
            name={name}
            ref={refValue}
            placeholder={placeholder}
            type={type}
            autoFocus={false}
            maxLength={maxLength}
          />
          {rightComponent !== undefined && rightComponent}
        </div>
      );

    case 'submit':
      const btnStyle = accent ? styles.accentBtn : styles.submitBtn;
      if (buttonDisabled) {
        return (
          <input
            type="submit"
            style={styles.disabledBtn}
            value={placeholder}
            disabled
          />
        );
      }
      return (
        <input
          type="submit"
          style={Object.assign({}, btnStyle, style)}
          value={placeholder}
          onClick={onClick}
        />
      );

    case 'checkbox':
      return (
        <input
          type="checkbox"
          name={name}
          id={name}
          value="Car"
          ref={refValue}
        />
      );
    default:
      return <></>;
  }
};

const styles = createStyle({
  inputContainer: {
    borderRadius: 30,
    padding: 10,
    backgroundColor: 'white',
    margin: '.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    height: 25,
  },
  inputStyle: {
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    outline: 'none',
    width: '100%',
    flex: 1,
    fontSize: '1rem',
  },
  submitBtn: {
    borderRadius: 30,
    borderColor: 'none',
    backgroundColor: tintColorLight,
    color: 'white',
    border: 'none',
    padding: 10,
    outline: 'none',
    margin: '.5rem',
    cursor: 'pointer',
    WebkitAppearance: 'none',
    fontSize: 16,
  },
  accentBtn: {
    borderRadius: 30,
    borderColor: 'none',
    backgroundColor: accentColor,
    color: 'white',
    border: 'none',
    padding: 10,
    outline: 'none',
    margin: '.5rem',
    cursor: 'pointer',
    WebkitAppearance: 'none',
    fontSize: 16,
    width: '90%',
  },
  disabledBtn: {
    borderRadius: 30,
    borderColor: 'none',
    backgroundColor: 'lightgray',
    color: 'white',
    border: 'none',
    padding: 10,
    outline: 'none',
    margin: '.5rem',
    cursor: 'not-allowed',
    WebkitAppearance: 'none',
    fontSize: 16,
    width: '90%',
  },
});

export default InputField;
